exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-case-studies-animus-js": () => import("./../../../src/pages/case-studies/animus.js" /* webpackChunkName: "component---src-pages-case-studies-animus-js" */),
  "component---src-pages-case-studies-auchan-js": () => import("./../../../src/pages/case-studies/auchan.js" /* webpackChunkName: "component---src-pages-case-studies-auchan-js" */),
  "component---src-pages-case-studies-eyelux-js": () => import("./../../../src/pages/case-studies/eyelux.js" /* webpackChunkName: "component---src-pages-case-studies-eyelux-js" */),
  "component---src-pages-case-studies-flatco-js": () => import("./../../../src/pages/case-studies/flatco.js" /* webpackChunkName: "component---src-pages-case-studies-flatco-js" */),
  "component---src-pages-case-studies-inner-balance-js": () => import("./../../../src/pages/case-studies/inner-balance.js" /* webpackChunkName: "component---src-pages-case-studies-inner-balance-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-case-studies-kutatok-ejszakaja-js": () => import("./../../../src/pages/case-studies/kutatok-ejszakaja.js" /* webpackChunkName: "component---src-pages-case-studies-kutatok-ejszakaja-js" */),
  "component---src-pages-case-studies-lampyon-js": () => import("./../../../src/pages/case-studies/lampyon.js" /* webpackChunkName: "component---src-pages-case-studies-lampyon-js" */),
  "component---src-pages-case-studies-spacecrop-js": () => import("./../../../src/pages/case-studies/spacecrop.js" /* webpackChunkName: "component---src-pages-case-studies-spacecrop-js" */),
  "component---src-pages-case-studies-tolnagro-js": () => import("./../../../src/pages/case-studies/tolnagro.js" /* webpackChunkName: "component---src-pages-case-studies-tolnagro-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-data-management-notice-hu-js": () => import("./../../../src/pages/data-management-notice-hu.js" /* webpackChunkName: "component---src-pages-data-management-notice-hu-js" */),
  "component---src-pages-data-management-notice-js": () => import("./../../../src/pages/data-management-notice.js" /* webpackChunkName: "component---src-pages-data-management-notice-js" */),
  "component---src-pages-eu-trainings-js": () => import("./../../../src/pages/eu-trainings.js" /* webpackChunkName: "component---src-pages-eu-trainings-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-space-js": () => import("./../../../src/pages/space.js" /* webpackChunkName: "component---src-pages-space-js" */)
}

